

export function getProfileImageUrl(path) {
        // Construct the full URL for the profile image

        //local
        // return path ? `http://127.0.0.1:8000${path}` : 'http://127.0.0.1:8000/images/default-profile-image.jpg';

        //production
        return path ? `https://systeme.cecosarl.com/public/${path}` : '/images/default-profile-image.jpg';

}


