
<template>
    

    <div class="px-5 md:px-10 md:px-10 py-14 w-full">
        <div class="w-full">
            <div class="flex items-center font-bold xs:text-2xl md:text-4xl">
                <h2 class="mx-auto relative border-b-4 pb-2 border-b-4 border-custom-yellow hover:border-b-4 hover:border-white">Notre Equipe</h2>
            </div>
            <div class="flex items-center mt-5">
                <p class="mx-auto relative">Une équipe bien expérimentée et dévouée pour votre satisfaction</p>
            </div>

            <div class="md:grid md:grid-cols-3 md:gap-5 p-5 md:px-10 justify-between">
                <div v-for="team in teams" :key="team.id" class="bg-white mt-5 md:mt-10 shadow-xl p-5">
                    <div class="p-5 flex items-center">
                        <img class="rounded-full mx-auto w-48 h-48 border-4 border-white shadow-xl" :src="handleImage(team.image)" alt>
                    </div>
                    <div class="flex items-center">
                        <h3 class="mx-auto ">{{team.title}}</h3>
                    </div>
                    <div class="flex items-center">
                        <p class="mx-auto font-bold">{{ team.full_name }}</p>
                    </div>
                    <div class="flex items-center">
                        <p class="mx-auto">{{ team.short_description }}</p>
                    </div>
                </div>                
            </div>
        </div>
    </div>

</template>

<script>
/* eslint-disable */

import { getTeams } from "../jscore/init.js";
import { getProfileImageUrl } from "../jscore/ImageHandler.js";

export default {
  name: "TeamComponent",

  data() {
    return {
      teams: {}
    };
  },

  mounted() {
    this.fetchTeams();
  },

  methods: {
    fetchTeams() {
      getTeams()
        .then(response => {
          this.teams = response.data.team;
        })
        .catch(error => {
          console.log(error);
        });
    },

    //image handler
    handleImage(path) {
      const image = getProfileImageUrl(path);
      return image;
    }
  }
};
</script>

<style>
</style>