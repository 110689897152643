<template>
    <div>
        <Header-component :titleMessage="indice"></Header-component>


        <div class="">	
            <div class="md:flex justify-between px-8 md:px-14 mt-14 md:mt-24 w-full pb-10">
                <div class="md:w-2/4">    
                    <div class="text-custom-yellow font-bold text-4xl">Congo Engineering Construction</div>
                    <p class="mt-14 border-t border-gray-700 py-5 text-md md:mr-36">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus maxime quae voluptas perferendis fugiat minima, excepturi quibusdam earum, sit neque doloribus? Fugiat voluptates quas illum excepturi minus numquam, tempora, maxime inventore cumque non qui labore ab delectus similique ratione sequi eius voluptatem totam quia, natus voluptatum. Est fuga ratione incidunt laborum enim vero tempora. Suscipit placeat a sequi eligendi similique voluptate eveniet neque voluptatibus, eum fugit animi veniam doloribus vero soluta temporibus atque explicabo? Vitae provident nesciunt delectus ut perferendis?
                    </p>

                </div>
                <div class="md:w-2/4">
                    <img src="https://cecosarl.com//images/about-p1.jpeg" class="shadow-xl border-4 border-white">
                </div>
            </div>	
            <div class="md:flex bg-gray-100 py-10">

                <div class="px-8 md:px-14 md:w-1/3">
                    <div class="text-custom-yellow py-5 md:py-0 font-bold text-2xl md:text-4xl w-2/3">
                        Nos services
                    </div>

                    <p class="mt-5 md:mt-10">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione temporibus ipsum odio deserunt atque vitae minus quibusdam assumenda, eius maiores doloribus quasi eos ut eum iure, dolore perspiciatis. Labore in soluta vel, sunt eveniet expedita culpa cum mollitia consectetur neque.
                    </p>
                </div>

                <div class="px-8 md:px-14 md:w-2/3">
                    <div v-for="service in services" :key="service.id" class="flex border-b mt-10 md:mt-14 border-gray-300 py-8 justify-between w-full hover:bg-gray-100 hover:cursor-pointer">
                        <div class="w-2/4 font-bold text-xl md:text-2xl hover:text-custom-yellow hover:cursor-pointer" @mouseover="showImage1 = true" @mouseleave="showImage1 = false">
                            {{ service.name }}
                        </div>
                        <div class="text-gray-400 w-2/4">
                            {{ truncate(service.description, 150) }}
                        </div>
                        <div v-if="showImage1" class="absolute flex justify-between left-1/2">
                            <img :src="handleImage(service.image)" alt="Image" class="w-48 h-44" />
                        </div>
                    </div>

                </div>
            </div>

            <TeamComponent></TeamComponent>

            <div class="px-5 md:px-14 bg-gray-100 gap-4 mt-10 md:mt-10 w-full">
                <div class="md:flex w-full md:gap-10 py-14 md:justify-between ">
                    <div class="bg-white md:w-1/4 text-center p-5">
                        <p class="font-bold text-4xl text-custom-yellow">10</p>
                        <p class="text-gray-400 mt-2">Années d'expérience</p>
                    </div>
                    <div class="mt-5 md:mt-0 bg-white md:w-1/4 text-center p-5">
                        <p class="font-bold text-4xl text-custom-yellow">{{ services.length }}</p>
                        <p class="text-gray-400 mt-2">Services</p>
                    </div>
                    <div class="mt-5 md:mt-0 bg-white md:w-1/4 text-center p-5">
                        <p class="font-bold text-4xl text-custom-yellow">{{ teams.length }}</p>
                        <p class="text-gray-400 mt-2">Employés</p>                        
                    </div>
                    <div class="mt-5 md:mt-0 bg-white md:w-1/4 text-center p-5">
                        <p class="font-bold text-4xl text-custom-yellow">{{ projects.length }}</p>
                        <p class="text-gray-400 mt-2">Projets</p>                                                
                    </div>
                </div>
            </div>	

            <div class="md:flex justify-between px-8 md:px-14 gap-4 mt-10 md:mt-14 w-full">
                <div class="md:w-2/4 pb-10">
                    <img src="https://cecosarl.com//images/const-1.jpeg" class="">
                </div>
                <div class="md:w-2/4 pb-10">
                    <img src="https://cecosarl.com//images/const-2.jpeg" class="">
                </div>
            </div>	
            
            
            <TestimonyComponent></TestimonyComponent>

            <footer-component></footer-component>
        </div>
    </div>

</template>

<script>

    /* eslint-disable */
    import HeaderComponent from "../../src/components/HeaderComponent.vue";
    import FooterComponent from "../../src/components/FooterComponent.vue";
    import TestimonyComponent from "../../src/components/TestimonyComponent.vue";
    import TeamComponent from "../../src/components/TeamComponent.vue";

    import { getTeams, getProjects, getServices } from "../jscore/init.js";
    import { getProfileImageUrl } from "../jscore/ImageHandler.js";

    export default {
        name: "AboutView",
        components: { HeaderComponent, FooterComponent, TestimonyComponent, TeamComponent },

        data(){
            return {
                showImage1: false,
                showImage2: false,
                showImage3: false,

        		indice: 'A propos',
                teams: {},
                projects: {},
                services: {}
            }
        },

        mounted() {
            this.fetchTeams();
            this.fetchProjects();
            this.fetchServices();
        },

        methods: {
            fetchTeams() {
            getTeams()
                .then(response => {
                this.teams = response.data.team;
                })
                .catch(error => {
                console.log(error);
                });
            },

            fetchProjects() {
            getProjects()
                .then(response => {
                this.projects = response.data.project;
                })
                .catch(error => {
                console.log(error);
                });
            },

            fetchServices() {
            getServices()
                .then(response => {
                this.services = response.data.service;
                })
                .catch(error => {
                console.log(error);
                });
            },

            handleImage(path){
                const image = getProfileImageUrl(path);
                return image;
            },

            truncate(text, length) {
                if (text.length > length) {
                    return text.substring(0, length) + '...';
                } else {
                    return text;
                }
            }
        }
    };
    
</script>

<style>
</style>