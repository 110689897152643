<template>
	<div class="bg-cover bg-center bg-no-repeat" style="background-image: url('https://cecosarl.com/images/footer-bg.jpg')">	
		<div class="py-14 px-5 md:px-24 text-white justify-between z-10 bg-black bg-opacity-80 text-white" >
			<div class="md:flex justify-between md:gap-14 pb-14 border-b border-gray-400">
				<div class="md:w-1/3">
					<div class="uppercase mt-5 font-bold text-2xl text-custom-yellow">CECO Sarl.</div>

					<div class="md:block mt-5 text-sm">RDC, Nord-Kivu, Goma</div>
					<div class="md:block text-sm">Katindo. Ref Salle des fetes LG Muvitho</div>
					<div class="md:mt-5 text-sm"><span class="font-bold">Phone:</span> +243 897 261 065 / +243 972 369 552</div>
					<div class="text-sm"><span class="font-bold">Email:</span> cecosarl1@gmail.com / alphonseben19@yahoo.fr</div>
				</div>

				<div class="md:w-1/3">
					<div class="uppercase mt-10 font-bold text-md text-custom-yellow">Pages</div>
					<div class="mt-2 text-sm">
						<router-link :to="{ name: 'home'}">
							Accueil
						</router-link>
					</div>
					<div class="md:mt-2 text-sm">
						<router-link :to="{ name: 'about'}">
							A propos
						</router-link>
					</div>
					<div class="md:mt-2 text-sm">
						<router-link :to="{ name: 'service'}">
							Services
						</router-link>
					</div>
					<div class="md:mt-2 text-sm">
						<router-link :to="{ name: 'project'}">
							Projets
						</router-link>
					</div>
				</div>

				<div class="md:w-1/3">
					<div class="uppercase mt-10 font-bold text-md text-custom-yellow">Services</div>
					<div v-for="service in services" :key="service.id" class="mt-2 text-sm">{{ service.name }}</div>
				</div>
			</div>
			
			<div class="flex items-center mt-10">
				<div class="mt-5 mx-auto text-xs">
					<p>©2024 Congo Engineering Construction Sarl. Tous droits réservés.</p>
					<div class="flex items-center">
						<p class="mx-auto">Designed by <a target="blank" href="https://pattechconsulting.com" class="text-custom-yellow">Pattech Consulting.</a></p>
					</div>
				</div>

			</div>
		</div>		
	</div>
	
</template>

<script>
/* eslint-disable */
import { getServices } from "../jscore/init.js";
import { getProfileImageUrl } from '../jscore/ImageHandler.js';

export default {
  name: "FooterComponent",

  data() {
    return {
	services: {},
    };
  },

  mounted(){
	this.fetchServices();
  },

  methods: {
    fetchServices(){

	getServices()
		.then(response => {
			this.services = response.data.service;
		})
		.catch(error => {
			console.log(error);
		});
	},
  }
};
</script>

<!-- "scoped" attribute to limit CSS to this component only -->
<style>
</style>

