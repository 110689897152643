//import axios library to handle requests

import axios from 'axios';


export function getServices(){
    return axios.get('/service')
}

export function getTeams(){
    return axios.get('/team')
}


export function getProjects(){
    return axios.get('/project')
}

export function getTestimonials(){
    return axios.get('/testimonial')
}

export function getArticles(){
    return axios.get('/blog')
}

export function getArticle(slug){
    return axios.get(`/blog/${slug}`)
}







