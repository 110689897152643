<template>
    <div class="px-5 md:px-10 md:px-10 py-14 w-full bg-gray-100">
        <div class="w-full">
            <div class="flex items-center font-bold xs:text-2xl md:text-4xl">
                <h2 class="mx-auto relative border-b-4 border-b-4 border-custom-yellow hover:border-b-4 hover:border-white">Services</h2>
            </div>
            <div class="flex items-center mt-5">
                <p class="mx-auto relative">Ce que nous offrons comme services</p>
            </div>

            <div class="md:grid md:grid-cols-3 md:gap-5 p-5 md:px-14">
                <div v-for="service in services" :key="service.id" class="gap-5 mt-5 md:mt-14 bg-white justify-between p-5">
                    <div class="w-1/3 p-5">
                        <img class="w-full" :src="handleImage(service.image)" alt>
                    </div>
                    <div class="px-5">
                        <h3 class="font-bold text-lg border-b-4 border-gray-100 hover:border-b-4 hover:border-custom-yellow">{{ service.name }}</h3>
                        <p class="mt-5">{{ service.description }}</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */

import { getServices } from "../jscore/init.js";
import { getProfileImageUrl } from '../jscore/ImageHandler.js';

export default {
  name: "ServiceComponent",

  data() {
    return {
        services: {}
    };
  },

  mounted(){
    this.fetchServices()
  },

  methods: {
    fetchServices(){

        getServices()
        .then(response => {
            this.services = response.data.service;
        })
        .catch(error => {
            console.log(error);
        });
    },

    //image handler
    handleImage(path){
        const image = getProfileImageUrl(path);
        return image;
    },


  }
};
</script>

<style>
</style>